import { useState, useEffect } from "react";

export function usePrintPreview() {
  const [isPrintPreview, setIsPrintPreview] = useState(false);

  useEffect(() => {
    const handleBeforePrint = () => {
      setIsPrintPreview(true);
    };

    const handleAfterPrint = () => {
      setIsPrintPreview(false);
    };

    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  return isPrintPreview;
}
